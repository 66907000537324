import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { Box, Drawer, Hidden, List, ListItem } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

// Components
import NavLink from "./NavLink";
import LanguageButton from "./LanguageButton";
import { StyledNavLink } from "./styles";
import { Link, StyledButton } from "../../common";
import { useTranslation } from "next-translate";

//Icons
import { ExitToApp } from "@material-ui/icons";
import NavActions from "./NavActions";

const StyledList = styled(List)({
  width: 256,
});

const MobileNav = ({ navItems, onMobileClose, openMobile }) => {
  const router = useRouter();
  const { lang, t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [router.pathname]);

  const navLinks = navItems.map((item) => (
    <ListItem key={item.title} disableGutters>
      <NavLink {...item} />
    </ListItem>
  ));

  return (
    <Hidden mdUp>
      <Drawer
        color="inherit"
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
      >
        <Box height="100%" display="flex" flexDirection="column">
          <Box p={2}>
            <StyledList>
              {navLinks}
              <ListItem onClick={onMobileClose}>
                <LanguageButton />
              </ListItem>
              <ListItem>
                <NavActions />
              </ListItem>
            </StyledList>
          </Box>
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default MobileNav;
