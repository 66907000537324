import MobileNav from "./MobileNav";
//Components
import Topbar from "./Topbar";
import { useState } from "react";
//Translation
import { useTranslation } from "next-translate";

const Navbar = () => {
  const { t } = useTranslation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const navItems = [
    {
      href: "/",
      title: t("navbar:home"),
    },
    {
      href: "/privacy",
      title: t("navbar:privacyPolicy"),
    },
  ];

  return (
    <>
      <Topbar
        navItems={navItems}
        onMobileNavOpen={() => setMobileNavOpen(true)}
        isMobileNavOpen={isMobileNavOpen}
      />
      <MobileNav
        navItems={navItems}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
    </>
  );
};

export default Navbar;
