import { Typography } from "@material-ui/core";

// Translation
import { useTranslation } from "next-translate";

//Components
import { Link } from "../../common";
import { StyledNavLink } from "./styles";
import RoundedNavLink from "./RoundedNavLink";

const NavLink = ({
  href,
  title,
  isRoundedButton,
  color = "primary",
  ...rest
}) => {
  const { lang } = useTranslation();
  const button = isRoundedButton ? (
    <RoundedNavLink href={href} title={title} />
  ) : (
    <StyledNavLink
      component={Link}
      href={href}
      lang={lang}
      underline="none"
      {...rest}
    >
      <Typography color={color}>{title}</Typography>
    </StyledNavLink>
  );
  return button;
};

export default NavLink;
