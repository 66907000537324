import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledBox = styled(Box)({
  "& img": {
    maxWidth: 125,
  },
});

const Logo = (props) => {
  return (
    <StyledBox display="flex" flexDirection="row" alignItems="center">
      <img alt="Logo" src="/images/logo.png" {...props} />
    </StyledBox>
  );
};

export default Logo;
