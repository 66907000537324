// Translation
import { useTranslation } from "next-translate";

//Components
import { Link } from "../../common";
import { StyledRoundedNavLink, StyledTypography } from "./styles";

const RoundedNavLink = ({ href, title }) => {
  const { lang } = useTranslation();
  return (
    <StyledRoundedNavLink underline="none">
      <Link href={href} lang={lang} underline="none" p={3}>
        <StyledTypography>{title}</StyledTypography>
      </Link>
    </StyledRoundedNavLink>
  );
};

export default RoundedNavLink;
