import { Box, Button, styled, Typography } from "@material-ui/core";

export default function StyledButton({ text, paddingX = 0, ...buttonProps }) {
  const textColor = buttonProps.color ? "inherit" : "primary";

  const ButtonRadius = styled(Button)(({ theme }) => ({
    borderWidth: 1,
    borderRadius: 2,
    borderColor: theme.palette.primary,
  }));

  return (
    <ButtonRadius disableElevation {...buttonProps}>
      <Box clone paddingY={0.5} paddingX={paddingX}>
        <Typography style={{ textTransform: "none" }} color={textColor}>
          {text}
        </Typography>
      </Box>
    </ButtonRadius>
  );
}
