import { useRouter } from "next/router";

// Translation
import { useTranslation } from "next-translate";
import { useLocalStorage } from "../../../hooks";

//Components
import { StyledNavLink } from "./styles";
import { Link } from "../../common";
import { Typography } from "@material-ui/core";

const LanguageButton = () => {
  const [_, setLocale] = useLocalStorage("locale");

  const { lang } = useTranslation();
  const isEnglish = lang === "en";

  const { asPath } = useRouter();
  const path = asPath.split`/`.filter((part) => part !== "en" && part !== "ar")
    .join`/`;

  const switchLanguage = () => {
    const newLanguage = isEnglish ? "ar" : "en";
    setLocale(newLanguage);
  };

  return (
    <StyledNavLink
      component={Link}
      href={`${path}/`}
      lang={isEnglish ? "ar" : "en"}
      underline="none"
      onClick={switchLanguage}
    >
      <Typography color="primary">{isEnglish ? "عربي" : "English"}</Typography>
    </StyledNavLink>
  );
};

export default LanguageButton;
