import { Box } from "@material-ui/core";

// Translation
import { useTranslation } from "next-translate";

//Icons
import { ExitToApp } from "@material-ui/icons";

//Components
import Link from "next/link";
import { StyledButton } from "../../common";
import { StyledNavLink } from "./styles";

const NavActions = () => {
  const { lang, t } = useTranslation();

  return (
    <>
      <StyledNavLink
        component={Link}
        href={"https://portal.joinstaffii.com"}
        lang={lang}
        underline="none"
      >
        <StyledButton
          text={t("navbar:login")}
          startIcon={
            <Box clone marginLeft={2}>
              <ExitToApp />
            </Box>
          }
        />
      </StyledNavLink>
      <StyledNavLink
        component={Link}
        href={"/comingSoon"}
        lang={lang}
        underline="none"
      >
        <StyledButton
          variant="contained"
          color="primary"
          text={t("navbar:start")}
          paddingX={4}
        />
      </StyledNavLink>
    </>
  );
};

export default NavActions;
