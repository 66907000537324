import { Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const StyledNavLink = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: "center",
  letterSpacing: 0,
  padding: "10px 8px",
  textTransform: "none",
  width: "100%",
  "&.active": {
    color: theme.palette.primary.dark,
  },
  "&:hover": {
    color: theme.palette.primary.dark,
  },
}));

export const StyledRoundedNavLink = styled(Button)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: "center",
  letterSpacing: 0,
  padding: "10px 8px",
  textTransform: "none",
  width: "100%",
  borderRadius: theme.spacing(12),
  border: `1px solid ${theme.palette.common.black}`,
  padding: "5px 20px",
  textDecoration: "none",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: "none",
  margin: 5,
}));
