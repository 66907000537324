import { useEffect } from "react";
import { useRouter } from "next/router";

// Translation
import { useTranslation } from "next-translate";
import { defaultLanguage } from "../../i18n.json";

// MUI
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import theme from "../../styles/theme";

// Hooks
import { useLocalStorage } from "../../hooks";

// Components
import Navbar from "./Navbar";
import Footer from "./Footer";

const PageWrapper = ({ children }) => {
  const router = useRouter();
  const { lang } = useTranslation();
  const [locale, _] = useLocalStorage("locale", defaultLanguage);

  const isEnglish = lang === "en";
  const localeMatch = lang === locale;
  const isDefaultLanguage = locale === defaultLanguage;

  const changeLanguage = () => {
    const url = router.asPath.split`/`.filter(
      (part) => part !== "en" && part !== "ar"
    ).join`/`;

    router.replace(isDefaultLanguage ? `${url}/` : `/${locale}${url}`);
  };

  useEffect(() => {
    if (!localeMatch) changeLanguage();
  }, []);

  // Change the direction of the page based on language
  theme.direction = isEnglish ? "ltr" : "rtl";

  const muiTheme = responsiveFontSizes(createMuiTheme(theme));

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <div dir={theme.direction}>
        <Navbar />
        {children}
        {/* <Footer /> */}
      </div>
    </ThemeProvider>
  );
};

export default PageWrapper;
