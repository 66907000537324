import { Box, Container } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledBox = styled(Box)(({ theme, background }) => ({
  background: theme.palette[background],
}));

const Section = ({ children, maxWidth, ...boxProps }) => (
  <StyledBox p={5} {...boxProps}>
    <Container maxWidth={maxWidth}>{children}</Container>
  </StyledBox>
);

export default Section;
