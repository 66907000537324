import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Grid,
  Box,
  Container,
} from "@material-ui/core";

// Translation
import { useTranslation } from "next-translate";

//Icons
import { Menu, Close } from "@material-ui/icons";

//Components
import { ElevationScroll, Link, Logo } from "../../common";
import NavLink from "./NavLink";
import LanguageButton from "./LanguageButton";
import NavActions from "./NavActions";

const Topbar = ({ navItems, onMobileNavOpen, isMobileNavOpen }) => {
  const { lang, t } = useTranslation();
  const isEnglish = lang === "en";

  const navLinks = navItems.map((item) => (
    <Grid key={item.title} item>
      <NavLink {...item} />
    </Grid>
  ));

  return (
    <ElevationScroll>
      <AppBar color="inherit" position="sticky">
        <Box paddingX={5} paddingY={2}>
          <Container>
            <Toolbar disableGutters>
              <Box
                clone
                paddingLeft={isEnglish ? 0 : 5}
                paddingRight={isEnglish ? 5 : 0}
              >
                <Link href="/" lang={lang}>
                  <Logo />
                </Link>
              </Box>
              <Box flexGrow={1} />
              <Hidden smDown>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justify={"flex-start"}
                >
                  {navLinks}
                  <Grid item>
                    <LanguageButton />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justify={"flex-end"}
                >
                  <Grid item>
                    <NavActions />
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <IconButton color="inherit" onClick={onMobileNavOpen}>
                  {isMobileNavOpen ? <Close /> : <Menu />}
                </IconButton>
              </Hidden>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
    </ElevationScroll>
  );
};

export default Topbar;
