const theme = {
  palette: {
    common: {
      black: "#232529",
    },
    primary: { main: "#3D5E77", dark: "#37556b" },
    secondary: { main: "#778ea0" },
    kaleGreen: "#83C374",
    foqluiBlue: "#60B3E0",
    mirindaGradient: "linear-gradient(180deg, #F4B04B 0%, #DC6E37 100%)",
    text: {
      primary: "#37454B",
      secondary: "#A9B4C4",
    },
    background: {
      paper: "#FFF",
      default: "#FFF",
    },
  },
  typography: {
    fontFamily: "Helvetica, Arial",
    h1: {
      fontSize: "2.187rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.56rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    button: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
};

export default theme;
